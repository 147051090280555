import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';

import './App.css';
import Sidebar from './Sidebar'; 
import SubcategoryView from './SubcategoryView';
import Offerings from './Offerings'; 
import About from './About'; 
import Collage from './Collage';  

function App() {
    return (
        <Router>
            <div className="App container-fluid app-container" >
                <div className="row" style={{backgroundColor: "black"}}>
                    {/* Sidebar */}
                    <div className="col-md-3 sidebar sidebar-border" style={{padding: "0px"}}>
                        <Sidebar />
                    </div>

                    {/* Main Content Area */}
                    <div className="col-md-9 main-content" style={{padding: "10px 20px 10px 20px"}}>
                        <Routes>
                            <Route path="/" element={<Navigate to="/collage" replace/>} />
                            <Route path="/category/:category/subcategory/:subcategory" element={<SubcategoryView />} />
                            <Route path="/category/:category/subcategory/:subcategory/:name" element={<SubcategoryView />} />
                            <Route path="/offerings" element={<Offerings />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/collage" element={<Collage />} />
                        </Routes>
                    </div>
                </div>
            </div>
        </Router>
    );
}

export default App;


