// src/Collage/index.js
import React, { useRef, useState, useEffect, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import FragmentCarousel from './FragmentCarousel';
import FragmentOnCanvas from './FragmentOnCanvas';
import { useNavigate } from 'react-router-dom';
import fetchFragmentUrl from '../utils/fetchFragmentUrl';

import html2canvas from "html2canvas";

import './index.css'

function Collage() {
  //hello
  const fragmentCanvasRef = useRef(null);
  const iocContainerRef = useRef(null);

  // LOAD CANVAS MODEL/STATE FROM LOCAL STORAGE
  const [imagesOnCanvas, setImagesOnCanvas] = useState(() => {
    console.log('Getting canvas state from local storage...');
    const savedState = localStorage.getItem('canvasState');
    if (savedState) {
      const parsedState = JSON.parse(savedState);
      return parsedState.filter(fragment =>
        fragment != null &&
        typeof fragment === 'object' &&
        fragment.largeThumbnailURL != null &&
        fragment.name != null &&
        fragment.id != null
      );
    }
    return [];
  });

  const [selectedFragmentId, setSelectedFragmentId] = useState(null);

  const isDraggingGhostRef = useRef(false);

  const navigate = useNavigate();

  const isDraggingFragmentRef = useRef(false);
  const isResizingFragmentRef = useRef(false);
  const isRotatingFragmentRef = useRef(false);

  // CAPTURE SCREENSHOT FUNCTION
  const captureScreenshot = () => {
    var canvasPromise = html2canvas(iocContainerRef.current, {
      useCORS: true,
      onclone: function (clonedDoc) {
        // Find the cloned version of the iocContainerRef div
        var clonedContainer = clonedDoc.querySelector('.images-on-canvas-container');

        // Ensure the cloned container is visible and has a black background
        clonedContainer.style.visibility = 'visible';
        clonedContainer.style.backgroundColor = 'black';
        clonedContainer.style.border = 'none';
        clonedContainer.style.color = 'black';

        // Recursively make all children of the cloned container visible
        function makeChildrenVisible(element) {
          element.classList.remove('fade-in'); // Remove the fade-in class

          element.style.visibility = 'visible';
          element.style.opacity = '1';
          element.style.display = 'block';

          // Hide rotation handles
          if (element.classList.contains('rotation-handle-container')) {
            element.style.display = 'none';
          } else {
            for (var i = 0; i < element.children.length; i++) {
              makeChildrenVisible(element.children[i]);
            }
          }
        }
        makeChildrenVisible(clonedContainer);
      }
    });

    canvasPromise.then((canvas) => {
      const croppedCanvas = document.createElement('canvas');
      const ctx = croppedCanvas.getContext('2d');
      croppedCanvas.width = canvas.width;
      croppedCanvas.height = canvas.height - 3;  // Reduce height by 3 pixels

      ctx.drawImage(canvas, 0, 0, canvas.width, canvas.height - 3, 0, 0, canvas.width, canvas.height - 3);

      croppedCanvas.toBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'collage_screenshot.png';
        link.style.display = 'none';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }, 'image/png');
    });
  }


  const handleSendToFront = () => {
    if (selectedFragmentId !== null) {
      setImagesOnCanvas((prevImages) => {
        const updatedImages = [...prevImages];

        if (updatedImages.length === 1) {
          // If there is only one fragment, no need to change its zIndex
          return updatedImages;
        }

        const maxZIndex = Math.max(...updatedImages.map((image) => image.zIndex));
        const selectedFragmentIndex = updatedImages.findIndex((image) => image.id === selectedFragmentId);

        if (selectedFragmentIndex !== -1) {
          const selectedZIndex = updatedImages[selectedFragmentIndex].zIndex;

          if (selectedZIndex === maxZIndex) {
            // If the selected fragment already has the highest zIndex, no need to change it
            return updatedImages;
          }

          // Set the zIndex of the selected fragment to the current max
          updatedImages[selectedFragmentIndex] = {
            ...updatedImages[selectedFragmentIndex],
            zIndex: maxZIndex,
          };

          // Adjust the zIndex of fragments that had a higher zIndex than the selected fragment
          updatedImages.forEach((image, index) => {
            if (index !== selectedFragmentIndex && image.zIndex > selectedZIndex) {
              updatedImages[index] = {
                ...image,
                zIndex: image.zIndex - 1,
              };
            }
          });
        }

        return updatedImages;
      });
    }
  };


  const handleSendToBack = () => {
    if (selectedFragmentId !== null) {
      setImagesOnCanvas((prevImages) => {
        const updatedImages = [...prevImages];

        if (updatedImages.length === 1) {
          // If there is only one fragment, no need to change its zIndex
          return updatedImages;
        }

        const minZIndex = Math.min(...updatedImages.map((image) => image.zIndex));
        const selectedFragmentIndex = updatedImages.findIndex((image) => image.id === selectedFragmentId);

        if (selectedFragmentIndex !== -1) {
          const selectedZIndex = updatedImages[selectedFragmentIndex].zIndex;

          if (selectedZIndex === minZIndex) {
            // If the selected fragment already has the lowest zIndex, no need to change it
            return updatedImages;
          }

          // Set the zIndex of the selected fragment to the current min
          updatedImages[selectedFragmentIndex] = {
            ...updatedImages[selectedFragmentIndex],
            zIndex: minZIndex,
          };

          // Adjust the zIndex of fragments that had a lower zIndex than the selected fragment
          updatedImages.forEach((image, index) => {
            if (index !== selectedFragmentIndex && image.zIndex < selectedZIndex) {
              updatedImages[index] = {
                ...image,
                zIndex: image.zIndex + 1,
              };
            }
          });
        }

        return updatedImages;
      });
    }
  };

  // HANDLE FLIP IMAGE
  const handleFlipImage = () => {
    if (selectedFragmentId !== null) {
      setImagesOnCanvas(prevImages =>
        prevImages.map(image =>
          image.id === selectedFragmentId
            ? { ...image, flipped: !image.flipped }
            : image
        )
      );
    }
  };

  // HANDLE DESELECT
  const handleDeselect = () => {
    setSelectedFragmentId(null);
  };

  // WHEN THE LEDGER CHANGES UPDATE THE STORED VERSION OF THE LEDGER
  useEffect(() => {
    //console.log('Saving canvas state to local storage:', imagesOnCanvas)
    // Save canvas state to local storage whenever it changes
    localStorage.setItem('canvasState', JSON.stringify(imagesOnCanvas));
  }, [imagesOnCanvas]);

  // REFRESH URLS AND LOAD FROM SAVED CANVAS STATE
  useEffect(() => {
    const refreshUrls = async () => {
      try {
        const updatedImagesOnCanvas = await Promise.all(
          imagesOnCanvas.map(async (image) => {
            try {
              const { largeThumbnailURL, mainPhoto } = await fetchFragmentUrl(image.name, image.fragmentIndex);
              return {
                ...image,
                largeThumbnailURL,
                mainPhoto
              };
            } catch (error) {
              console.error(`Error fetching URL for ${image.name} at index ${image.fragmentIndex}:`, error);
              return image; // Return the original image object if there's an error
            }
          })
        );
        setImagesOnCanvas(updatedImagesOnCanvas);
        localStorage.setItem('imagesOnCanvas', JSON.stringify(updatedImagesOnCanvas));
      } catch (error) {
        console.error('Error refreshing fragment URLs:', error);
      }
    };

    const initializeCanvas = async () => {
      await refreshUrls();

      // Check if there's fragment data in local storage to add to the canvas
      const selectedFragmentData = localStorage.getItem('fragmentDataForCollage');
      if (selectedFragmentData) {
        const fragmentData = JSON.parse(selectedFragmentData);
        console.log('selectedFragmentData:', fragmentData);
        handleAddFragment(fragmentData);
        localStorage.removeItem('fragmentDataForCollage'); // Clear the fragment data after adding
      }
    };

    initializeCanvas();
  }, []);


  // FUNCTION FOR ADDING A FRAGMENT FROM INFORMATIONAL SIDE (VIA MODAL)
  const handleAddFragment = useCallback((fragmentData) => {
    console.log("fragmentData to add to canvas from modal", fragmentData);
    const { largeThumbnailURL, fragmentIndex, recordName, recordDescription, recordMainPhoto, recordCategory, recordSubcategory } = fragmentData;
    const img = new Image();
    img.src = largeThumbnailURL;

    img.onload = () => {
      const canvasRect = iocContainerRef.current.getBoundingClientRect();
      const aspectRatio = img.width / img.height;
      const initialWidth = canvasRect.width * 0.3;
      const initialHeight = initialWidth / aspectRatio;
      const initialX = (canvasRect.width / 2) - (initialWidth / 2);
      const initialY = (canvasRect.height / 2) - (initialHeight / 2);

      setImagesOnCanvas((prevImages) => [
        ...prevImages,
        {
          id: uuidv4(),
          largeThumbnailURL: largeThumbnailURL,
          fragmentIndex: fragmentIndex,
          name: recordName,
          description: recordDescription,
          mainPhoto: recordMainPhoto,
          category: recordCategory,
          subcategory: recordSubcategory,
          x: initialX,
          y: initialY,
          width: initialWidth,
          height: initialHeight,
          zIndex: prevImages.length + 1,
          rotationAngle: 0,
          flipped: false

        },
      ]);
    };
  }, []);


  // WHEN WE CLICK THE CANVAS, WE DESELECT THE FRAGMENT
  useEffect(() => {
    const handleCanvasClick = (event) => {
      console.log("TOP OF HANDLE CANVAS CLICK");
      console.log("selectedFragmentId: ", selectedFragmentId);
      console.log("class list: ", event.target.classList);
      console.log("event.target: ", event.target);

      if (selectedFragmentId !== null) {
        console.log("selectedFragmentId is not null");
      } else {
        console.log("<>selectedFragmentId is null");
      }

      if (iocContainerRef.current) {
        console.log("iocContainerRef.current exists");
      } else {
        console.log("<>iocContainerRef.current does not exist");
      }

      if (iocContainerRef.current && iocContainerRef.current.contains(event.target)) {
        console.log("Clicked inside iocContainerRef");
      } else {
        console.log("<>Clicked outside iocContainerRef");
      }

      if (!event.target.classList.contains('rotation-handle') ||
        !event.target.classList.contains('rotation-handle-container') ||
        !event.target.classList.contains('fragment-image-img')) {
        console.log("Clicked element is not rotation-handle or rotation-handle-container or fragment-image-img");
      } else {
        console.log("<>Clicked element is rotation-handle or rotation-handle-container");
      }

      if (
        selectedFragmentId !== null &&
        iocContainerRef.current &&
        iocContainerRef.current.contains(event.target) &&
        !event.target.classList.contains('rotation-handle') &&
        !event.target.classList.contains('rotation-handle-container') &&
        !event.target.classList.contains('fragment-image-img') &&
        !isDraggingFragmentRef.current &&
        !isResizingFragmentRef.current &&
        !isRotatingFragmentRef.current
      ) {
        console.log("setting selectedFragmentId to null because all conditions are met");
        setSelectedFragmentId(null);
      }
      else {
        console.log("NOT setting selectedFragmentId to null because all conditions are not met");
      }
    };

    const canvasElement = iocContainerRef.current;
    if (canvasElement) {
      canvasElement.addEventListener('click', handleCanvasClick);
    }

    return () => {
      if (canvasElement) {
        canvasElement.removeEventListener('click', handleCanvasClick);
      }
    };
  }, [selectedFragmentId]);



  // SET THE FRAGMENT AS THE SELECTED FRAGMENT WHEN CLICKED
  const handleFragmentClick = (id) => {
    console.log('Clicked fragment with id:', id);
    setSelectedFragmentId(id);
  };

  // HANDLER FOR DELETING FRAGMENT
  const handleDeleteFragment = (id) => {
    if (id !== null) {
      setImagesOnCanvas(prevImages => {
        const deletedImage = prevImages.find(image => image.id === id);
        if (!deletedImage) return prevImages; // If the image to delete is not found, return the original array

        const deletedZIndex = deletedImage.zIndex;

        return prevImages
          .filter(image => image.id !== id) // Remove the deleted image
          .map(image => {
            // Decrease zIndex by 1 for all images that had a higher zIndex than the deleted image
            if (image.zIndex > deletedZIndex) {
              return { ...image, zIndex: image.zIndex - 1 };
            }
            return image;
          });
      });
      setSelectedFragmentId(null);
    }
  };


  // HANDLER FOR CLEARING THE CANVAS
  const handleClearCanvas = () => {
    setImagesOnCanvas([]);
    setSelectedFragmentId(null);
  };

  // CALL THIS TO UPDATE THE "MODEL", THE LEDGER
  const handlePositionChange = useCallback((index, x, y) => {
    setImagesOnCanvas(prevImages => {
      const updatedImages = [...prevImages];
      updatedImages[index] = {
        ...updatedImages[index],
        x,
        y,
      };
      return updatedImages;
    });
  }, []);

  // CALL THIS TO UPDATE THE "MODEL", THE LEDGER
  const handleSizeChange = useCallback((index, width, height, x, y) => {
    setImagesOnCanvas(prevImages => {
      const updatedImages = [...prevImages];
      updatedImages[index] = {
        ...updatedImages[index],
        width,
        height,
        x,
        y
      };
      return updatedImages;
    });
  }, []);

  const handleRotationChange = useCallback((index, rotationAngle) => {
    setImagesOnCanvas(prevImages => {
      const updatedImages = [...prevImages];
      updatedImages[index] = {
        ...updatedImages[index],
        rotationAngle
      };
      return updatedImages;
    });
  }, []);

  // FUNCTION TO NAVIGATE TO THE INFORMATIONAL SIDE
  const handleGoToSource = () => {
    if (selectedFragmentId !== null) {
      const selectedFragment = imagesOnCanvas.find((image) => image.id === selectedFragmentId);

      if (selectedFragment) {
        const { category, subcategory, name, ...fragmentData } = selectedFragment;
        navigate(`/category/${category}/subcategory/${subcategory}/${encodeURIComponent(name)}`, {
          state: { selectedFragment: fragmentData },
        });
      }
    }
  };

  // WHEN WE START THE DRAG, A GHOST IMAGE APPEARS
  const handleDragStart = useCallback((event, fragment) => {
    console.log('Drag started');
    console.log("Before setting isDraggingGhostRef.current to true: ", isDraggingGhostRef.current);
    isDraggingGhostRef.current = true;
    console.log("After setting isDraggingGhostRef.current to true: ", isDraggingGhostRef.current);

    const ghostImg = new Image();
    ghostImg.src = fragment.largeThumbnailURL;
    ghostImg.style.position = 'fixed';
    ghostImg.style.pointerEvents = 'none';
    ghostImg.style.opacity = '0.8';
    ghostImg.style.zIndex = '99999';
    ghostImg.style.width = '100px';
    ghostImg.style.height = 'auto';
    ghostImg.style.transform = 'translate(-50%, -50%)';

    document.body.appendChild(ghostImg);

    const onMove = (event) => {
      if (iocContainerRef.current) {
        const touch = event.touches ? event.touches[0] : event;
        const x = touch.clientX;
        const y = touch.clientY;
        ghostImg.style.left = `${x}px`;
        ghostImg.style.top = `${y}px`;
      }
    };

    // ADDING A FRAGMENT BY DRAGGING FROM THE CAROUSEL
    const onEnd = (event) => {
      console.log('Top of drag end');

      document.body.removeChild(ghostImg);

      document.removeEventListener('mousemove', onMove);
      document.removeEventListener('touchmove', onMove);
      document.removeEventListener('mouseup', onEnd);
      document.removeEventListener('touchend', onEnd);

      if (iocContainerRef.current) {

        const canvasRect = iocContainerRef.current.getBoundingClientRect();
        const touch = event.changedTouches ? event.changedTouches[0] : event;
        const dropX = touch.clientX - canvasRect.left;
        const dropY = touch.clientY - canvasRect.top;

        if (
          dropX >= 0 &&
          dropX <= canvasRect.width &&
          dropY >= 0 &&
          dropY <= canvasRect.height
        ) {
          const img = new Image();
          img.src = fragment.largeThumbnailURL;

          img.onload = () => {
            const aspectRatio = img.width / img.height;
            const initialWidth = canvasRect.width * 0.3;
            console.log("initialWidth: ", initialWidth);
            console.log("canvasRect.width: ", canvasRect.width);
            const initialHeight = initialWidth / aspectRatio;

            // Calculate the initial position based on the drop position
            let initialX = dropX - initialWidth / 2;
            let initialY = dropY - initialHeight / 2;

            // Adjust the position if the fragment would be outside the canvas
            if (initialX < 0) {
              initialX = 0;
            } else if (initialX + initialWidth > canvasRect.width) {
              initialX = canvasRect.width - initialWidth;
            }

            if (initialY < 0) {
              initialY = 0;
            } else if (initialY + initialHeight > canvasRect.height) {
              initialY = canvasRect.height - initialHeight;
            }

            setImagesOnCanvas((prevImages) => [
              ...prevImages,
              {
                id: uuidv4(),
                largeThumbnailURL: fragment.largeThumbnailURL,
                name: fragment.name,
                description: fragment.description,
                mainPhoto: fragment.mainPhoto,
                category: fragment.category,
                subcategory: fragment.subcategory,
                x: initialX,
                y: initialY,
                width: initialWidth,
                height: initialHeight,
                fragmentIndex: fragment.fragmentIndex,
                zIndex: prevImages.length + 1,
                rotationAngle: 0,
                flipped: false,
              },
            ]);
          };
        }
      }

      isDraggingGhostRef.current = false;


    };

    const onMouseMove = (event) => {
      onMove(event);
    };

    const onTouchMove = (event) => {
      //event.preventDefault();
      onMove(event);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('touchmove', onTouchMove, { passive: false });
    document.addEventListener('mouseup', onEnd);
    document.addEventListener('touchend', onEnd);
  }, []);


  useEffect(() => {
    const handleMouseMove = (event) => {
      if (iocContainerRef.current && isDraggingGhostRef.current) {
        console.log('Dragging - handleMouseMove');
        const ghostImg = iocContainerRef.current.querySelector('.ghost-image');
        if (ghostImg) {
          const canvasRect = iocContainerRef.current.getBoundingClientRect();
          const x = event.clientX - canvasRect.left;
          const y = event.clientY - canvasRect.top;
          ghostImg.style.left = `${x}px`;
          ghostImg.style.top = `${y}px`;
        }
      }
    };

    const handleTouchMove = (event) => {
      if (isDraggingGhostRef.current) {
        console.log('Dragging ghost img - handleTouchMove');
        //event.preventDefault();
        handleMouseMove(event.touches[0]);
      }
    };


    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('touchmove', handleTouchMove, { passive: false });


    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('touchmove', handleTouchMove);

    };

  }, []);

  return (
    <div>
      <FragmentCarousel onDragStart={handleDragStart} />

      {/* Canvas Container */}
      <div
        style={{
          width: '100%',
          maxWidth: '800px',
          margin: '0 auto',
          overflow: 'hidden',
          marginTop: "10px",
          zIndex: 1,

        }}
      >
        {/* Fragment Canvas */}
        <div
          ref={fragmentCanvasRef}
          className="fragment-canvas"
          style={{
            width: '100%',
            paddingTop: '75%', // Maintain aspect ratio (600 / 800 = 0.75)
            position: 'relative',
            backgroundColor: 'none',
            zIndex: 9999,
            border: '2px dashed blue',



          }}
        >
          {/* Images on Canvas Container */}
          <div
            ref={iocContainerRef}
            className='images-on-canvas-container'
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              marginBottom: '5px',
              paddingBottom: '5px',



            }}
          >
            {imagesOnCanvas.map((fragment, index) => (
              <FragmentOnCanvas
                key={fragment.id} // Use the unique id generated by uuid as the key
                fragment={{
                  largeThumbnailURL: fragment.largeThumbnailURL,
                  name: fragment.name,
                  x: fragment.x,
                  y: fragment.y,
                  width: fragment.width,
                  height: fragment.height,
                  description: fragment.description,
                  mainPhoto: fragment.mainPhoto,
                  category: fragment.category,
                  subcategory: fragment.subcategory,
                  zIndex: fragment.zIndex,
                  rotationAngle: fragment.rotationAngle,
                  flipped: fragment.flipped
                }}
                canvasRef={fragmentCanvasRef}
                iocContainerRef={iocContainerRef}
                isSelected={selectedFragmentId === fragment.id}
                onClickHandler={() => handleFragmentClick(fragment.id)}
                onPositionChange={handlePositionChange.bind(null, index)}
                onSizeChange={handleSizeChange.bind(null, index)}
                onRotationChange={handleRotationChange.bind(null, index)}
                isDraggingFragmentRef={isDraggingFragmentRef}
                isResizingFragmentRef={isResizingFragmentRef}
                isRotatingFragmentRef={isRotatingFragmentRef}


              />
            ))}
          </div>

        </div>
      </div>

      {/* IMAGES ON CANVAS STATE DEV INFO */}
      {/* <div style={{ textAlign: 'left', marginTop: '10px' }}>
        <h3>Images on Canvas State Dev Info:</h3>
        <pre>{JSON.stringify(imagesOnCanvas, null, 2)}</pre>
      </div> */}

      {/* CONTROL PANEL */}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: '15px',
          paddingTop: '15px',
          maxWidth: '800px',
          margin: '0 auto',
        }}
      >
        {/* LEFT SIDE BUTTONS */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
          {/* DELETE BUTTON */}
          <div style={{ marginBottom: '10px' }}>
            <button
              onClick={() => handleDeleteFragment(selectedFragmentId)}
              disabled={selectedFragmentId === null}
              className={`btn btn-dark ${selectedFragmentId === null ? 'disabled' : ''}`}
            >
              Delete Fragment
            </button>
          </div>

          {/* CLEAR CANVAS BUTTON */}
          <div style={{ marginBottom: '10px' }}>
            <button
              onClick={handleClearCanvas}
              disabled={imagesOnCanvas.length === 0}
              className={`btn btn-dark ${imagesOnCanvas.length === 0 ? 'disabled' : ''}`}
            >
              Clear Canvas
            </button>
          </div>

          {/* DESELECT BUTTON */}
          <div style={{ marginBottom: '10px' }}>
            <button
              onClick={handleDeselect}
              disabled={selectedFragmentId === null}
              className={`btn btn-dark ${selectedFragmentId === null ? 'disabled' : ''}`}
            >
              Deselect
            </button>
          </div>
        </div>

        {/* RIGHT SIDE BUTTONS */}
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
          {/* SEND TO BACK BUTTON */}
          <div style={{ marginBottom: '10px' }}>
            <button
              onClick={handleSendToBack}
              disabled={selectedFragmentId === null}
              className={`btn btn-dark ${selectedFragmentId === null ? 'disabled' : ''}`}
            >
              Send To Back
            </button>
          </div>

          {/* SEND TO FRONT BUTTON */}
          <div style={{ marginBottom: '10px' }}>
            <button
              onClick={handleSendToFront}
              disabled={selectedFragmentId === null}
              className={`btn btn-dark ${selectedFragmentId === null ? 'disabled' : ''}`}
            >
              Send To Front
            </button>
          </div>

          {/* FLIP HORIZONTALLY BUTTON */}
          <div style={{ marginBottom: '10px' }}>
            <button
              onClick={handleFlipImage}
              disabled={selectedFragmentId === null}
              className={`btn btn-dark ${selectedFragmentId === null ? 'disabled' : ''}`}
            >
              Flip Horizontally
            </button>
          </div>

          {/* TAKE SCREENSHOT BUTTON */}
          <div>
            <button className="btn btn-dark" onClick={captureScreenshot}>
              Save
            </button>
          </div>
        </div>
      </div>



      {/* INFORMATION ABOUT SELECTED FRAGMENT */}
      <div
        className='fragmentSourceInfoContainer'
        style={{
          textAlign: 'left',
          padding: '10px',
          border: '1px solid #ccc',
          borderRadius: '8px',
          margin: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center' // Center the content horizontally
        }}
      >
        {selectedFragmentId !== null ? (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px' }}>
            {(() => {
              const selectedFragment = imagesOnCanvas.find((image) => image.id === selectedFragmentId);

              if (selectedFragment) {
                if (selectedFragment.category === 'Altar') {
                  // IF ALTAR FRAGMENT
                  return (
                    <>
                      <h3 style={{ marginBottom: '10px' }}><strong style={{ fontWeight: '900' }}>Altar Fragment</strong></h3>
                      <p style={{ marginBottom: '5px' }}><strong style={{ fontWeight: '900' }}>Name:</strong> {selectedFragment.name}</p>
                      {selectedFragment.description && (
                        <p style={{ marginBottom: '10px' }}><strong style={{ fontWeight: '900' }}>Description:</strong> {selectedFragment.description}</p>
                      )}
                    </>
                  );
                } else {
                  // IF NOT ALTAR FRAGMENT
                  return (
                    <>
                      <h3 style={{ marginBottom: '10px' }}><strong style={{ fontWeight: '900' }}>Fragment Source: </strong>{selectedFragment.name}</h3>
                      <p style={{ marginBottom: '5px' }}><strong style={{ fontWeight: '900' }}>Category:</strong> {selectedFragment.category}</p>
                      <p style={{ marginBottom: '10px' }}><strong style={{ fontWeight: '900' }}>Subcategory:</strong> {selectedFragment.subcategory}</p>
                      {selectedFragment.mainPhoto && (
                        <img
                          src={selectedFragment.mainPhoto}
                          alt="Main Photograph"
                          style={{ maxWidth: '200px', marginBottom: '10px', borderRadius: '4px' }}
                        />
                      )}
                      {selectedFragment.description && (
                        <p style={{ marginBottom: '10px' }}><strong style={{ fontWeight: '900' }}>Source Description:</strong> {selectedFragment.description}</p>
                      )}
                      <button className='btn btn-dark' onClick={handleGoToSource} style={{ marginTop: '10px' }}>Go To Source</button>
                    </>
                  );
                }
              }
            })()}
          </div>
        ) : (
          <p style={{ fontStyle: 'italic', color: '#888' }}>Select a fragment to see its source information here.</p>
        )}

      </div>


    </div>
  );
}

export default Collage;



