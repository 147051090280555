import React, { useLayoutEffect, useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import useSubcategoryData from './useSubcategoryData';
import interact from 'interactjs';
import SubcategoryEntity from '../SubcategoryEntity';
import SubcategoryEntityModal from '../SubcategoryEntityModal';

import './index.css';

function SubcategoryView() {
    const { category, subcategory, name } = useParams();
    const { records, loading, error } = useSubcategoryData(category, subcategory);
    const navigate = useNavigate();
    const canvasRef = useRef(null);
    const [hoveredId, setHoveredId] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [movement, setMovement] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const modalOpenedRef = useRef(false);

    useLayoutEffect(() => {
        //console.log('Name:', name);
        //console.log('Records:', records);

        if (name && records.length > 0 && !modalOpenedRef.current) {
            const decodedName = decodeURIComponent(name);
            const matchingRecord = records.find(record => record.name === decodedName);
            //console.log('Matching Record:', matchingRecord);

            if (matchingRecord) {
                setSelectedRecord(matchingRecord);
                setIsModalOpen(true);
                modalOpenedRef.current = true;
            }
        }
    }, [name, records]);

    useEffect(() => {
        if (!canvasRef.current) return;

        interact('.draggable-container').draggable({
            inertia: {
                resistance: 6,
                minSpeed: 5,
                maxSpeed: 50,
                endSpeed: 5
            },
            modifiers: [
                interact.modifiers.restrict({
                    restriction: canvasRef.current,
                    endOnly: false,
                    elementRect: { left: 0, top: 0, right: 1, bottom: 1 }
                })
            ],
            autoScroll: true,
            onstart: () => {
                setMovement(0);
            },
            onmove: event => {
                const target = event.target;
                const newX = (parseFloat(target.getAttribute('data-x')) || 0) + event.dx;
                const newY = (parseFloat(target.getAttribute('data-y')) || 0) + event.dy;

                target.style.transform = `translate(${newX}px, ${newY}px)`;
                target.setAttribute('data-x', newX);
                target.setAttribute('data-y', newY);

                setMovement(prev => prev + Math.abs(event.dx) + Math.abs(event.dy));
            },

        });

        return () => interact('.draggable-container').unset();
    }, [records]);

    const handleEntityClick = record => {
        if (movement <= 3) {  // Check movement instead of dragging state
            setSelectedRecord(record);
            setIsModalOpen(true);
            navigate(`/category/${category}/subcategory/${subcategory}/${encodeURIComponent(record.name)}`);
        }
        setMovement(0);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedRecord(null);
        navigate(`/category/${category}/subcategory/${subcategory}`);
    };

    const getColorClass = (category) => {
        switch (category.toLowerCase()) {
            case 'art':
                return 'border-art';
            case 'context':
                return 'border-context';
            case 'place':
                return 'border-place';
            case 'stewards':
                return 'border-stewards';
            default:
                return '';
        }
    };

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error loading data: {error.message}</div>;

    return (
        <div style={{padding: '5px 15px 15px 15px'}}>
            <h2 style={{ fontFamily: 'Avenir, sans-serif' }}>
                <span style={{ fontStyle: 'italic' }}>{subcategory}</span>
            </h2>
            <div>

                <div ref={canvasRef}
                    className={`canvas ${getColorClass(category)}`}
                    style={{ position: 'relative', width: '100%', height: '89vh', padding: 0, margin: 0, overflow: 'hidden' }}>
                    {records.map(record => (
                        <SubcategoryEntity
                            key={record.id}
                            record={record}
                            isHovered={hoveredId === record.id}
                            onMouseEnter={() => setHoveredId(record.id)}
                            onMouseLeave={() => setHoveredId(null)}
                            onClick={() => handleEntityClick(record)}
                            canvasRef={canvasRef}

                        />
                    ))}
                </div>
            </div>
            {selectedRecord && (
                <SubcategoryEntityModal
                    isOpen={!!selectedRecord}
                    onClose={handleCloseModal}
                    record={selectedRecord}
                />
            )}
        </div>
    );

}

export default SubcategoryView;










